import { Component } from '@angular/core';
import { CardBannerDirective } from '../card-banner-scroll.directive';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state/app.state';
import * as tagManagerActions from '../../../store/actions/tagmanager.actions';
import { TagmanagerBuilder } from 'src/app/builders/tagmanager-builder';

@Component({
  selector: 'app-card-banner-scroll',
  templateUrl: './card-banner-scroll.component.html',
  styleUrls: ['./card-banner-scroll.component.scss']
})
export class CardBannerScrollComponent extends CardBannerDirective {

  constructor(private store?: Store<AppState>) {
    super();
  }

  public tagManager(): void {
    window?.dataLayer?.push(
      TagmanagerBuilder.buildBannerSelectPromotion({ ...this.bannerItem, bannerPosition: 'footer' })
    );
    this.store.dispatch(tagManagerActions.pushMainBannerTagManager({ banner: this.bannerItem }));
  }
}
