import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { TagmanagerBuilder } from "../../builders/tagmanager-builder";
import * as tagManagerActions from "../actions/tagmanager.actions";

@Injectable()
export class TagManagerEffects {
  private browserGlobals = {
    windowRef(): any {
      return window;
    },
  };

  pushItemToCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tagManagerActions.pushItemToCart),
        tap((props) => {
          const window = this.browserGlobals.windowRef();
          window.dataLayer.push(TagmanagerBuilder.buildItemCart(props.item));
        }),
        catchError((error) => throwError(() => error))
      ),
    { dispatch: false }
  );

  pushTagBannerMenu$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tagManagerActions.pushBannerMenuTagManager),
        tap((props) => {
          const window = this.browserGlobals.windowRef();
          window.dataLayer.push(
            TagmanagerBuilder.buildBannerMenu(props.banner)
          );
        }),
        tap((props) => (window.location.href = props.banner.link)),
        catchError((error) => throwError(() => error))
      ),
    { dispatch: false }
  );

  pushBannerNameHome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tagManagerActions.pushBannerNameTagManager),
        tap((props) => {
          const window = this.browserGlobals.windowRef();
          window.dataLayer.push(
            TagmanagerBuilder.buildBannerHome(props.banner)
          );
        }),
        catchError((error) => throwError(() => error))
      ),
    { dispatch: false }
  );

  pushBannerNameDepartaments$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tagManagerActions.pushBannerNameDepartamentsTagManager),
        tap((props) => {
          const window = this.browserGlobals.windowRef();
          window.dataLayer.push(
            TagmanagerBuilder.buildBannerNameDepartaments(props.banner)
          );
        }),
        catchError((error) => throwError(() => error))
      ),
    { dispatch: false }
  );

  pushTagMainBannerMob$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tagManagerActions.pushMainBannerTagManager),
        tap((props) => {
          const window = this.browserGlobals.windowRef();
          window.dataLayer.push(
            TagmanagerBuilder.buildBannerHome(props.banner)
          );
        }),
        catchError((error) => throwError(() => error))
      ),
    { dispatch: false }
  );

  pushUserData$ = createEffect(() => this.actions$.pipe(
    ofType(tagManagerActions.pushUserData),
    tap(({ customer }) => {
      const window = this.browserGlobals.windowRef();
      window.dataLayer.push(TagmanagerBuilder.buildUserData(customer));
    }),
    catchError((error) => throwError(() => error))),
    { dispatch: false }
  );

  setUserProperty$ = createEffect(() => this.actions$.pipe(
    ofType(tagManagerActions.setUserProperty),
    tap(({ customer, persona }) => {
      window.dataLayer.push({
        event: 'setUserProperty',
        ecommerce: {
          name: customer.name,
          persona,
        }
      });
    }),
    catchError((error) => throwError(() => error))),
    { dispatch: false }
  );

  pushBannerView$ = createEffect(() => this.actions$.pipe(
    ofType(tagManagerActions.pushBannerView),
    tap(({ banner }) => {
      const window = this.browserGlobals.windowRef();
      window.dataLayer.push(
        TagmanagerBuilder.buildBannerViewPromotion(banner)
      );
    }),
    catchError((error) => throwError(() => error))),
    { dispatch: false }
  );

  constructor(private actions$: Actions) { }
}
