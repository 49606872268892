import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Showcase } from '../home/showcase-model';

@Injectable({
  providedIn: 'root'
})
export class ShowCaseService {

  constructor(
    private http: HttpClient, @Inject(APP_BASE_HREF)
    private baseHref: string,
  ) { }

  private buildParam(convenance?: string | number, uf?: string): Params {
    let params = {};
    if (convenance) {
      params = { ...params, covenantCode: convenance };
    }
    params = { ...params, uf };
    return params;
  }

  private options(): {} {
    return {
      'app-token': environment.appToken,
      'user-id': '1',
      'client-ip': '123'
    };
  }

  getShowCase(convenance?: string | number, uf?: string): Observable<Showcase[]> {
    return this.http.get<Showcase[]>(`${this.baseHref}api/v2/home/show-case`, {
      headers: this.options(),
      params: this.buildParam(convenance, uf)
    });
  }

  getRecommendationCustomer(convenance?: string | number, uf?: string): Observable<Showcase[]> {
    return this.http.get<Showcase[]>(`${this.baseHref}api/v1/customer/recommendations`, {
      headers: this.options(),
      params: this.buildParam(convenance, uf)
    });
  }

  getRecommendationCustom(convenance?: string | number, uf?: string): Observable<Showcase[]> {
    return this.http.get<Showcase[]>(`${this.baseHref}api/v3/affiliate-marketing/custom-showcases?page=HOME&device=PERSONALIZATION`, {
      headers: this.options(),
      params: this.buildParam(convenance, uf)
    });
  }
}
