import { isPlatformBrowser } from '@angular/common';
import { Directive, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { interval, Subject, takeUntil } from 'rxjs';
import { ShowcaseBanner } from 'src/app/services/home/showcase-banner-model';
import { pushBannerView } from 'src/app/store/actions/tagmanager.actions';
import { AppState } from 'src/app/store/state/app.state';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[appBanner]'
})
export class BannerDirective implements OnDestroy, OnInit {
  @Input() public banners: ShowcaseBanner;
  public source = interval(7 * 1000);
  private destroy$ = new Subject();
  public oneBanner: boolean;
  public theme = environment.theme;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private store: Store<AppState>,
  ) {
    this.banners = {} as ShowcaseBanner;
  }

  ngOnInit(): void {
    this.startTimer();
    this.oneBanner = this.banners?.banners?.length > 2;
    if (this.banners?.banners?.[0]) {
      this.store.dispatch(pushBannerView({
        banner: { ...this.banners?.banners?.[0], bannerPosition: 'footer' }
      }));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  public prev(): void {
    this.banners.banners.unshift(this.banners.banners.pop());
  }

  public next(): void {
    this.banners.banners.push(this.banners.banners.shift());
  }

  startTimer(): void {
    if (this.banners?.autoplay) {
      if (isPlatformBrowser(this.platformId)) {
        this.source.pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.next();
        });
      }
    }
  }
}
