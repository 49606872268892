<div *ngIf="showcase" class="brand showcase" #myComponent>
  <ng-container *ngIf="type === 'recommendation'" [ngTemplateOutlet]="recommendation"></ng-container>
  <ng-container *ngIf="type === 'banner'" [ngTemplateOutlet]="contentBanner"></ng-container>
  <ng-container *ngIf="type === 'story' && showcase?.story?.stories?.length > 0"
    [ngTemplateOutlet]="storyShowcase"></ng-container>
</div>

<ng-template #recommendation>
  <ng-container *ngIf="innerWidth < 1200">
    <app-showcase-recommendation-grid *ngIf="layout === 'grid'"
      [recommendation]="showcase.recommendation"></app-showcase-recommendation-grid>

    <app-showcase-recommendation-slide *ngIf="layout === 'scroll'"
      [recommendation]="showcase.recommendation"></app-showcase-recommendation-slide>
  </ng-container>

  <app-showcase-recommendation-slide *ngIf="innerWidth >= 1200"
    [recommendation]="showcase.recommendation"></app-showcase-recommendation-slide>
</ng-template>

<ng-template #storyShowcase>
  <ng-container *ngIf="innerWidth >= 500">
    <app-showcase-story-carousel [showcaseStory]="showcase.story" [size]="layout === 'brand' ? 'small' : 'large'">
    </app-showcase-story-carousel>
  </ng-container>

  <ng-container *ngIf="innerWidth < 500">
    <app-showcase-story-mobile-carousel [story]="showcase?.story"
      [layoutType]="layout === 'brand' ? 'singleRow' : 'doubleRow'">
    </app-showcase-story-mobile-carousel>
  </ng-container>
</ng-template>

<ng-template #contentBanner>
  <app-content-banner *ngIf="layout === 'contentBanner'" [contentBanner]="showcase.banner"></app-content-banner>
  <app-banner-noscroll *ngIf="layout === 'noScroll'" [banners]="showcase?.banner"></app-banner-noscroll>
  <app-banner-scroll-carousel *ngIf="layout === 'scroll'" [banners]="showcase?.banner"></app-banner-scroll-carousel>
</ng-template>