import { Directive, Input } from '@angular/core';
import { Banner } from 'src/app/services/home/banner-model';

@Directive({
  selector: '[appCardBanner]'
})
export class CardBannerDirective {
  @Input() public bannerItem: Banner;

  constructor() {
    this.bannerItem = {} as Banner;
  }

}
