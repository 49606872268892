<section *ngIf="banners" appSwipe (swipeLeft)="nextBanner()" (swipeRight)="prevBanner()" class="container-banner"
  [ngClass]="theme" #mainBanner>
  <div class="main-banner" [ngClass]="{'one-banner': banners?.length <= 1}">
    <div *ngIf="banners?.length > 1" class="main-banner__arrow" (click)="prevBanner()"></div>
    <div (click)="sendSelectBanner()">
      <app-main-banner-image [banner]="banners[imageIndex].images"></app-main-banner-image>
    </div>
    <div *ngIf="banners?.length > 1" class="main-banner__arrow is-right" (click)="nextBanner()"></div>
  </div>

  <div class="banner-controls" [ngClass]="theme" *ngIf="banners?.length > 1">
    <div *ngFor="index of bannerArrayIndex; let index = index" class="banner-controls__dot"
      [ngClass]="{'active': index === imageIndex}" (click)="selectBanner(index)"></div>
  </div>
</section>