import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ContentBanner} from 'src/app/services/home/content-banner-model';

@Component({
  selector: 'app-content-banner',
  templateUrl: './content-banner.component.html',
  styleUrls: ['./content-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentBannerComponent {
  @Input() contentBanner: ContentBanner;
  public class: string;
  public textButton: string;

  constructor() {
    this.textButton = 'SAIBA MAIS';
  }

  private scrollToDiv(element: HTMLElement) {
    this.class = '';
    element.scrollIntoView();
  }

  public changeValues(element: HTMLElement) {
    this.class === 'animated' ? this.scrollToDiv(element) : this.class = 'animated';
    this.textButton === 'SAIBA MAIS' ? this.textButton = 'FECHAR' : this.textButton = 'SAIBA MAIS';
  }

}
