<lib-showcase-title-v2 [theme]="theme" [title]="recommendation.title"
  [subtitle]="recommendation?.subtitle"></lib-showcase-title-v2>
<div class="showcase-gallery">
  <div *ngFor="let item of items; let index = index">
    <div class="mobile-margin">
      <ng-container>
        <lib-card-item [blockOrientationHorizontal]="true" [theme]="theme" (emitPanvelCode)="addProduct($event)"
          (emitForAffiliateEvent)="affiliateEvent(item)" [item]="item" [useTagPresentation]="true"
          [showcaseInfo]="{ name: recommendation.title, id: recommendation.id, index }"></lib-card-item>
      </ng-container>
    </div>
  </div>
</div>
