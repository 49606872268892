import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-banner-skeleton',
  templateUrl: './banner-skeleton.component.html',
  styleUrls: ['./banner-skeleton.component.scss']
})
export class BannerSkeletonComponent {
  backgroundColor = environment.theme === 'DARK' ? '#31363F' : '#EBE8E8C2'
}
