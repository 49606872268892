<div #container class="content-banner-info">
  <div [class]="'container'"
       [style.backgroundImage]="'url('+ contentBanner.banners[0].image +')'"
  >
    <div class="content">
      <h2 class="title">
        {{contentBanner.title}}
      </h2>
      <div class="divider-title"></div>
      <div class="subTitle">
        <p class="text" [innerHTML]="contentBanner.htmlDescription" [class]="class"></p>
      </div>
      <button (click)="changeValues(container)">{{textButton}}</button>
    </div>
  </div>

</div>
