<div *ngIf="statics">
  <app-shipping-bar *ngIf="statics" [shippingBar]="statics?.topBar"></app-shipping-bar>

  <div *ngIf="statics?.toolbars" class="container-info-bar">
    <app-info-bar-desktop [toolBar]="statics?.toolbars" *ngIf="innerWidth > 960"></app-info-bar-desktop>
    <app-info-bar-mobile [toolBar]="statics?.toolbars" *ngIf="innerWidth <= 960"></app-info-bar-mobile>
  </div>

  <div *ngIf="statics?.stories as stories" class="container-stories">
    <div *ngFor="let sto of stories">
      <div *ngIf="sto?.type === 'story' && sto?.story?.stories?.length > 0 && innerWidth >= 500"
        class="container-stories-single">
        <app-showcase-story-carousel [showcaseStory]="sto?.story" size="large"></app-showcase-story-carousel>
      </div>
      <div *ngIf="sto?.type === 'story' && sto?.story?.stories?.length > 0 && innerWidth < 500"
        class="container-stories-single">
        <app-showcase-story-mobile-carousel *ngIf="sto?.story.type === 'category'" [story]="sto?.story"
          layoutType="doubleRow"></app-showcase-story-mobile-carousel>
      </div>
    </div>
  </div>
</div>
