import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  PLATFORM_ID,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { CookieService } from "ngx-cookie";
import { Cart } from "panvel-utils-lib/lib/models/shopping-cart-response.model";
import { filter, fromEvent, map, Observable, pairwise, Subject, take, takeUntil, throttleTime } from "rxjs";
import { Showcase } from "src/app/services/home/showcase-model";
import {
  loadShowCase,
  loadShowCaseSuccess
} from "src/app/store/actions/show-case.actions";
import { getShowCase } from "src/app/store/selectors/show-case.selectors";
import { AppState } from "src/app/store/state/app.state";
import { environment } from "src/environments/environment";

const INCREMENT_VALUE = 2;

@Component({
  selector: 'app-dynamic-loading-v2',
  template: `
    <ng-container *ngIf="showcases$ | async as showcases; else loadShowcase">
      <app-home-content-showcase
        *ngFor="let showcase of showcases.slice(0,limit);"
        [showcase]="showcase"
      ></app-home-content-showcase>
    </ng-container>
    <ng-template #loadShowcase>
      <app-showcase-skeleton></app-showcase-skeleton>
    </ng-template>
  `,
})
export class DynamicLoadingV2Component implements OnDestroy, OnInit {
  public limit = 3;
  public theme = environment.theme;

  private destroy$ = new Subject<void>();
  public showcases$: Observable<Showcase[]>;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.showcases$ = this.store.select(getShowCase);
  }

  ngOnInit(): void {
    if (this.isSpa) {
      this.observerScroll$
        .pipe(take(1))
        .subscribe(() => this.getAllShowCases());

      this.incrementLimit();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.store.dispatch(loadShowCaseSuccess({ data: null }))
  }

  private incrementLimit() {
    this.observerScroll$
      .pipe(throttleTime(200))
      .subscribe(() => {
        this.limit += INCREMENT_VALUE;
      })
  }

  private getAllShowCases() {
    this.route.data
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: { item: any }) => {
        const cart = data.item?.cart as Cart;
        const uf = this.cookieService.get('UF') ?? 'RS';
        const convenance = cart?.convenance?.find(({ active }) => active)?.code;
        this.store.dispatch(loadShowCase({ convenance, uf }));
      });

  }

  private get observerScroll$() {
    return fromEvent(window, 'scroll')
      .pipe(
        takeUntil(this.destroy$),
        map(() => window.scrollY),
        pairwise(),
        filter(([prev, curr]) => curr > prev)
      )
  }

  get isSpa(): boolean { return isPlatformBrowser(this.platformId) }
}
