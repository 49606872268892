<div class="container" >
    <a 
      class="img-container"
      [href]="bannerItem.link" 
      (click)="tagManager()"
    >
      <picture>
        <source
          *ngFor="let image of bannerItem.responsiveImages"
          [media]="image.media"
          [srcset]="image.responsiveImage">
        <img loading="lazy" [alt]="bannerItem.campaign" [src]="bannerItem.image" class="img-mobile">
      </picture>
    </a>
</div>

