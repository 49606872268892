import { Component } from '@angular/core';
import { BannerDirective } from '../banner-scroll.directive';

@Component({
  selector: 'app-banner-scroll-carousel',
  templateUrl: './banner-scroll-carousel.component.html',
  styleUrls: ['./banner-scroll-carousel.component.scss']
})
export class BannerScrollCarouselComponent extends BannerDirective {}


