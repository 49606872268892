import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppNotFoundComponent } from './component/not-found/not-found.component';
import { CartResolver } from './services/cart-resolver/cart-resolver.service';
import { HomeComponent } from './home/home.component';
import { HealthCheckComponent } from './component/health-check/health-check.component';

const routes: Routes = [
  {
    path: 'health-check',
    pathMatch: 'full',
    component: HealthCheckComponent,
  },
  {
    path: '',
    component: HomeComponent,
    runGuardsAndResolvers: "always",
    resolve: {
      item: CartResolver,
    },
    pathMatch: 'full',
  },
  {
    path: 'panvel',
    component: HomeComponent,
    resolve: {
      item: CartResolver,
    },
    pathMatch: 'full',
  },
  {
    path: 'panvel/main.do',
    component: HomeComponent,
    resolve: {
      item: CartResolver,
    },
    pathMatch: 'full',
  },
  {
    path: '404',
    component: AppNotFoundComponent,
    resolve: {
      item: CartResolver,
    }
  },
  {
    path: '**',
    redirectTo: '404'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 0],
      scrollPositionRestoration: 'top'
    }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
