<div [className]="'affiliate-'+ recommendation?.type?.toLowerCase()">
  <lib-showcase-title-v2 [theme]="theme" [title]="recommendation.title"
    [subtitle]="recommendation?.subtitle"></lib-showcase-title-v2>
  <div class="showcase-carousel">
    <app-arrow-button (leftButton)="prev()" (rightButton)="next()" [recommendationTitle]="recommendation.title">
      <div class="slides" [style.justify-content]="items?.length < 5 && 'center'">
        <div *ngFor="let item of items; let index = index">
          <div class="mobile-margin">
            <ng-container>
              <lib-card-item [blockOrientationHorizontal]="true" [theme]="theme" (emitPanvelCode)="addProduct($event)"
                (emitForAffiliateEvent)="affiliateEvent(item)" (emitClick)="affiliateEventWithHref($event)"
                [eventClick]="true" [item]="item" [useTagPresentation]="true"
                [showcaseInfo]="{ name: recommendation.title, id: recommendation.id, index}"></lib-card-item>
            </ng-container>
          </div>
        </div>
      </div>
    </app-arrow-button>
  </div>
</div>
