<div class="isDesktop skeleton-container">
  <div class="skeleton-content">
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      animation="progress"
      [theme]="{
        'background-color': backgroundColor,
        height: '64px',
        width: '95vw'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div class="skeleton-content">
    <ngx-skeleton-loader count="1" appearance="line" animation="progress" [theme]="{
          'background-color': backgroundColor,
          height: '421px',
          width: '95vw'
        }"></ngx-skeleton-loader>
  </div>
</div>

<div class="isMobile skeleton-container">
  <div class="skeleton-content">
    <ngx-skeleton-loader count="1" appearance="line" animation="progress" [theme]="{
          'background-color': backgroundColor,
          height: '274px',
          width: '95vw'
        }"></ngx-skeleton-loader>
  </div>
</div>
