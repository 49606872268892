import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ViewComponentService } from 'panvel-utils-lib';
import { interval, Subject, switchMap, takeUntil } from 'rxjs';
import { ContentBanner } from 'src/app/services/banners/models/banner.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-banner',
  templateUrl: './main-banner.component.html',
  styleUrls: ['./main-banner.component.scss']
})
export class MainBannerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() banners: ContentBanner[];
  @Input() theme: string = environment.theme;
  @Input() persona: string;

  @ViewChild("mainBanner") mainBanner!: ElementRef;
  private bannersSent = new Set<number>();

  // CONTROLS
  imageIndex = 0;
  bannerLength: number;
  bannerArrayIndex: number[];
  private destroy$ = new Subject();

  private reset$ = new Subject<void>();
  private stop$ = new Subject<void>();
  private interval$ = this.reset$.pipe(
    switchMap(() =>
      interval(7000).pipe(takeUntil(this.stop$))
    )
  );

  constructor(private readonly viewService: ViewComponentService) {
    this.interval$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.nextBanner(false));
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
    this.stopInterval();
  }

  ngOnInit(): void {
    if (this.banners) {
      this.bannerLength = this.banners?.length - 1;
      this.bannerArrayIndex = Array.from({ length: this.bannerLength + 1 }, (_, index) => index);
    }
  }

  ngAfterViewInit(): void {
    if (this.banners) {
      this.viewService
        .build({ component: this.mainBanner, threshold: 0.8, closeOnceRequest: false, checkHidden: true })
        .pipe(takeUntil(this.destroy$))
        .subscribe((isShowing) => {
          if (isShowing) {
            this.sendViewBanner();
            this.startInterval();
          } else this.stopInterval();
        })
    }
  }

  // CONTROLS
  resetInterval() {
    this.reset$.next();
  }

  stopInterval() {
    this.stop$.next();
  }

  startInterval() {
    this.stop$ = new Subject<void>();
    this.reset$.next();
  }

  nextBanner(resetInterval = true) {
    if (this.imageIndex < this.bannerLength) this.imageIndex += 1;
    else this.imageIndex = 0;
    if (resetInterval) this.resetInterval();
    this.sendViewBanner();
  }

  prevBanner(resetInterval = true) {
    if (this.imageIndex > 0) this.imageIndex -= 1;
    else this.imageIndex = this.bannerLength;
    if (resetInterval) this.resetInterval();
    this.sendViewBanner();
  }

  selectBanner(index: number) {
    this.imageIndex = index;
    this.resetInterval();
  }

  // EVENTS
  sendViewBanner() {
    const currentIndex = this.imageIndex;
    if (!this.bannersSent.has(currentIndex)) {
      this.bannersSent.add(currentIndex);
      window?.dataLayer?.push(
        this.eventBanner(this.banners[currentIndex], currentIndex, 'view_promotion'),
        this.eventBannerV2(this.banners[currentIndex], currentIndex, 'view_home_banner')
      );
    }
  }

  sendSelectBanner() {
    const currentIndex = this.imageIndex;
    window?.dataLayer?.push(
      this.eventBanner(this.banners[currentIndex], currentIndex, 'select_promotion'),
      this.eventBannerV2(this.banners[currentIndex], currentIndex, 'select_home_banner'),
      this.bannerHomeGTM(this.banners[currentIndex])
    );
  }

  eventBanner(banner: ContentBanner, index: number, event: 'select_promotion' | 'view_promotion') {
    return {
      event,
      ecommerce: {
        creative_slot: `${banner?.title} - ${this.persona}`,
        creative_name: 'Banner Principal',
        promotion_id: banner?.id ?? '',
        promotion_name: `${banner?.title} - ${banner?.images?.[0]?.screenReaderDescription}`,
        promotion_index: index
      }
    };
  }

  eventBannerV2(banner: ContentBanner, index: number, event: 'select_home_banner' | 'view_home_banner') {
    return {
      event,
      ecommerce: {
        creative_slot: "Banner",
        creative_name: 'Banner Principal',
        promotion_id: banner?.id ?? '',
        promotion_name: `${banner?.title} - ${banner?.images?.[0]?.screenReaderDescription}`,
        promotion_index: index
      }
    };
  }

  bannerHomeGTM(banner: ContentBanner): any {
    return {
      event: 'bannerHome',
      bannerName: banner?.title ?? '',
      bannerCampaign: banner?.title ?? '',
      persona: this.persona
    };
  }
}
