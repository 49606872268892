<div class="container-isLast-{{last}}">
    <a 
      [className]=" oneImage ? 'img-container-isFirst-{{first}} imgs' :  'imgs-not'"
      [href]="bannerItem.link" 
      (click)="tagManager()"
    >
      <picture>
        <source
          *ngFor="let image of bannerItem.responsiveImages"
          [media]="image.media"
          [srcset]="image.responsiveImage">
        <img [alt]="bannerItem.campaign" [src]="bannerItem.image" loading="lazy">
      </picture>
    </a>
</div>

