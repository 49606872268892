import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { Item } from 'src/app/services/home/item-model';
import { Recommendation } from 'src/app/services/home/recommendation-model';
import { AppState } from 'src/app/store/state/app.state';
import { WindowRefService } from '../../services/window-ref.service';
import * as ItemAction from '../../store/actions/item.actions';
import { Observable, Subject, tap } from "rxjs";
import { ShowcaseChaordic } from "../../services/home/showcase.chaordic-model";
import { getShowcaseChaordic } from "../../store/selectors/chaordic.selectors";
import { map, takeUntil } from "rxjs/operators";
import { sendShowcasesClick, setEventClick } from "../../store/actions/event.actions";
import { TagmanagerBuilder } from 'src/app/builders/tagmanager-builder';
import { Cart } from 'panvel-utils-lib/lib/models/shopping-cart-response.model';
import { getCart } from 'src/app/store/selectors/item.selectors';
import { TagIconsResponse } from 'panvel-utils-lib/lib/components/card-item/interface/item-response';
import { environment } from 'src/environments/environment';

@Directive({ selector: '[appShowcaseRecommendation]' })
export class ShowcaseRecommendationDirective implements OnInit, AfterViewInit {

  @Input() recommendation: Recommendation;
  @Input() isCustomer: boolean;
  @Input() forceEventEmit: boolean = false;

  private chaordicShowCase$: Observable<ShowcaseChaordic[]>;
  private destroy$ = new Subject<void>();
  private cart$: Observable<Cart>;
  public theme = environment.theme;

  public items: Item[];
  public innerWidth: number;
  private isVerificatedCoupon = new Set();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRef: WindowRefService,
    private store?: Store<AppState>
  ) {
    this.recommendation = {} as Recommendation;
    this.chaordicShowCase$ = this.store.select(getShowcaseChaordic);
    this.cart$ = this.store.select(getCart);
  }

  ngAfterViewInit(): void {
    this.eventViewItemList();
    this.initCouponVerification();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = this.windowRef.nativeWindow.innerWidth;
      if (this.recommendation?.items) this.items = this.getFormatedItems();
    }
  }

  private getFormatedItems(): Item[] {
    return this.recommendation.items.reduce((acc, current) => {
      const { id, title } = this.recommendation;
      const symbol = current.link.includes("?") ? '&' : '?';
      const link = encodeURI(`${current.link}${symbol}showcaseId=${id || title}&showcaseName=${title}`);

      acc.push({ ...current, dealPrice: current?.discount?.dealPrice, link });
      return acc;
    }, []);
  }

  public prev(): void {
    const lastItemIndex = this.items.length - 1;
    this.productCouponVerification(lastItemIndex);
    this.items.unshift(this.items.pop());
  }

  public next(): void {
    this.items.push(this.items.shift());
    this.productCouponVerification(4);
  }

  public addProduct(item: { panvelCode: number | string; quantity: number, price: number }): void {
    this.store.dispatch(ItemAction.addItemToCart({ item }));
  }

  // COUPONS
  private initCouponVerification() {
    this.cart$
      .pipe(takeUntil(this.destroy$))
      .subscribe(cart => {
        if (cart?.coupons) {
          const cartCouponIds = new Set(
            cart?.coupons
              .filter(({ discountType }) => ['FIXED_PRICE', 'PERCENTAGE_DISCOUNT'].includes(discountType))
              .map(coupon => coupon?.couponId)
          );

          const limit = window.innerWidth > 1200 ? 5 : this.items?.length;
          this.items.slice(0, limit).forEach(item => {
            if (item?.coupons) {
              const haveCoupon = item?.coupons?.some(productCouponId => cartCouponIds.has(productCouponId));
              if (haveCoupon) {
                if (item?.tagIcons?.length) {
                  item.tagIcons = [...item?.tagIcons, this.couponTAG];
                } else {
                  item.tagIcons = [this.couponTAG];
                }
              }
            }
            this.isVerificatedCoupon.add(item.id)
          })
        }
      })
  }

  private productCouponVerification(index: number) {
    const nextItem = this.items[index];
    const itemId = nextItem?.id;
    const itemCoupons = nextItem?.coupons;

    if (!itemId || this.isVerificatedCoupon.has(itemId) || !itemCoupons) {
      return;
    }
    this.cart$
      .pipe(takeUntil(this.destroy$))
      .subscribe(cart => {
        if (cart?.coupons && nextItem?.coupons) {
          const productCouponIds = new Set(nextItem.coupons);
          const haveCoupon = cart?.coupons
            .filter(({ discountType }) => ['FIXED_PRICE', 'PERCENTAGE_DISCOUNT'].includes(discountType))
            .find(({ couponId }) => productCouponIds.has(couponId));

          if (haveCoupon) {
            if (nextItem?.tagIcons?.length) {
              nextItem.tagIcons = [...nextItem.tagIcons, this.couponTAG];
            } else {
              nextItem.tagIcons = [this.couponTAG];
            }
            this.items[index] = nextItem;
          }
        }
        this.isVerificatedCoupon.add(nextItem.id);
      })
  }

  get couponTAG(): TagIconsResponse {
    return {
      tag: "COUPON_AVAILABLE",
      tagDescription: "CUPOM DISPONÍVEL",
      responsiveImage: [
        {
          responsiveImage: "https://cdn1.staticpanvel.com.br/cdn_service/svg/account-images/coupon-available.svg",
          media: ""
        }
      ]
    }
  }

  // EVENTS
  private eventViewItemList() {
    window?.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        page: "home_page",
        location_id: "home_page",
        item_list_id: this.recommendation.id || this.recommendation.title,
        item_list_name: this.recommendation.title || this.recommendation.id,
        items: this.recommendation.items.reduce((acc, curr, index) => {

          const item_variant = curr?.relatedProducts?.specifications?.[0]?.values
            .find(value => value.selected)?.name ?? '';

          acc.push({
            item_id: curr.id,
            item_name: curr.name,
            index: index,
            item_brand: curr.brandName,
            item_category: curr.itemCategory1,
            item_category2: curr.itemCategory2,
            item_category3: curr.itemCategory3,
            item_variant,
            price: curr?.discount?.dealPrice ?? curr?.originalPrice,
            quantity: 1
          })
          return acc
        }, []),
      }
    });
  }

  public affiliateEventWithHref(item: Item) {
    window.dataLayer.push(TagmanagerBuilder.showcaseEventClick(item, {
      id: this.recommendation.id,
      name: this.recommendation.title,
    }))
    const trackingUrlItem = item?.clickUrl || item?.trackingUrl;
    const redirect = item.link;
    if (trackingUrlItem) {
      this.store.dispatch(setEventClick({ affiliateEvents: { urlEvents: [trackingUrlItem], link: redirect } }))
    } else if (redirect) {
      window.location.href = redirect;
    }
  }

  public affiliateEvent(item: Item): void {
    if (item?.trackingUrl && !this.forceEventEmit) {
      this.chaordicShowCase$.pipe(
        map(chaordicArray =>
          chaordicArray
            .filter(ic => ic.id === this.recommendation.id)
            .flatMap(i => i.items)
            .filter(c => `${c.id}` === item.id)
            .reduce((_, curr) => {
              this.store.dispatch(sendShowcasesClick({ trackingId: curr.trackingUrl, panvelCode: Number(curr.id) }));
              return curr;
            }, null),
          tap(() => {
            this.destroy$.next();
            this.destroy$.complete();
          })
        )
      ).subscribe();
    } else if (item?.trackingUrl && this.forceEventEmit) {
      this.store.dispatch(sendShowcasesClick({ trackingId: item.trackingUrl, panvelCode: Number(item.id) }));
    }
  }
}
