import { Component } from '@angular/core';
import {ShowcaseRecommendationDirective} from '../showcase-recommendation.directive';

@Component({
  selector: 'app-showcase-recommendation-grid',
  templateUrl: './showcase-recommendation-grid.component.html',
  styleUrls: ['./showcase-recommendation-grid.component.scss']
})
export class ShowcaseRecommendationGridComponent extends ShowcaseRecommendationDirective {
}
