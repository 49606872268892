import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Observable, Subject, takeUntil, tap } from "rxjs";
import { Showcase } from "../../services/home/showcase-model";
import { Store } from "@ngrx/store";
import { AppState } from "../../store/state/app.state";
import { getShowCaseCustom } from "../../store/selectors/show-case.selectors";
import { sendShowcasesImpression } from 'src/app/store/actions/event.actions';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-home-content-custom',
  templateUrl: './home-content-custom.component.html',
  styleUrls: ['./home-content-custom.component.scss']
})
export class HomeContentCustomComponent implements OnInit, OnDestroy {
  public showcaseCustom$: Observable<Showcase[]>;
  public showcases: Showcase[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    @Inject(PLATFORM_ID) private platformId,

  ) {
    this.showcaseCustom$ = this.store.select(getShowCaseCustom);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.showcaseCustom$.pipe(
        takeUntil(this.destroy$),
        tap(showcases => {
          if (showcases) {
            showcases.forEach(showcase => {
              this.store.dispatch(sendShowcasesImpression({ impressionUrl: showcase?.recommendation.impressionUrl }));
              const items = showcase.recommendation?.items?.reduce((acc, current) => {
                acc.push({ ...current, link: `${current.link}?trackingUrl=${current.trackingUrl}` })
                return acc;
              }, []);
              this.showcases = [...this.showcases, { ...showcase, recommendation: { ...showcase.recommendation, items } }]
            })
          }
        })
      ).subscribe();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
