import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { WindowRefService } from '../../services/window-ref.service';
import { environment } from "../../../environments/environment";
import { StaticsResponse } from 'src/app/services/home/statics.model';

@Component({
  selector: 'app-home-content-statics',
  templateUrl: './home-content-statics.component.html',
  styleUrls: ['./home-content-statics.component.scss'],
})
export class HomeContentStaticsComponent implements OnInit {

  @Input() statics: StaticsResponse;

  public innerWidth: number;
  public theme = environment.theme

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private windowRef: WindowRefService
  ) { }

  ngOnInit(): void {
    this.updateInnerWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.updateInnerWidth();
  }

  updateInnerWidth(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = this.windowRef.nativeWindow.innerWidth;
    }
  }

}
