<section *ngIf="isSpa">
  <div class="header-nav">
    <div class="header-light">
      <lib-panvel-header
        [static]="statics$ | async"
        [cart]="cart$ | async"
        [config]="config"
        (deleteShoppingCart)="shoppingCartDelete()"
        (newCart)="sendCart($event)"
        (cepEvent)="cepEvent($event)"
        (notificationEvent)="notificationEvent($event)"
        (repurchaseEvent)="sendRepurchaseEvent($event)"
        (repurchaseEventMobile)="sendRepurchaseEvent($event)"
      ></lib-panvel-header>
    </div>
  </div>

  <div class="home">
    <app-main-banner
      *ngIf="banners$ | async as banners; else skeletonBanner"
      [banners]="banners"
      [persona]="persona"
    ></app-main-banner>

    <app-home-content-statics
      *ngIf="statics$ | async as statics; else skeletonStatics"
      [statics]="statics"
    ></app-home-content-statics>

    <app-home-content-customer [theme]="globalTheme" *appShowIfScroll=".5"></app-home-content-customer>
    <app-home-content-custom [theme]="globalTheme" *appShowIfScroll=".5"></app-home-content-custom>
    <app-dynamic-loading-v2></app-dynamic-loading-v2>
    <app-cookie></app-cookie>
  </div>

  <div class="footer">
    <lib-p-footer [theme]="globalTheme" *appShowIfScroll></lib-p-footer>
  </div>
</section>

<ng-template #skeletonBanner>
  <app-banner-skeleton></app-banner-skeleton>
</ng-template>

<ng-template #skeletonStatics>
  <app-statics-skeleton></app-statics-skeleton>
</ng-template>
