<lib-showcase-title-v2 [theme]="theme" [title]="banners.title" [subtitle]="banners?.subtitle"></lib-showcase-title-v2>
<div class="showcase-carousel-scroll">
  <div (click)="prev()" class="left-button">
    <div class="icn-left"></div>
  </div>
  <div class="slides">
    <div *ngFor="let banner of banners.banners;">
      <div class="mobile-margin">
        <app-card-banner-scroll [bannerItem]="banner">
        </app-card-banner-scroll>
      </div>
    </div>
  </div>
  <div (click)="next()" class="right-button">
    <div class="icn-right"></div>
  </div>
</div>