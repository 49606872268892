<lib-showcase-title-v2 [theme]="theme" [title]="banners.title" [subtitle]="banners?.subtitle"></lib-showcase-title-v2>
<div class="showcase-carousel-noscroll">
  <div [className]="oneBanner ? 'slides' : 'slide-one'">
    <div *ngFor="let banner of banners.banners; let first = first; let last = last">
      <div class="mobile-margin">
        <app-card-banner-noscroll [bannerItem]="banner" [first]="first" [last]="last" [oneImage]="oneBanner">
        </app-card-banner-noscroll>
      </div>
    </div>
  </div>
</div>