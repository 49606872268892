import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID, ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ViewComponentService } from 'panvel-utils-lib';
import { Observable, switchMap, take } from 'rxjs';

import { Showcase } from 'src/app/services/home/showcase-model';
import { ShowcaseChaordic } from 'src/app/services/home/showcase.chaordic-model';
import { getShowcaseChaordic } from 'src/app/store/selectors/chaordic.selectors';
import { AppState } from 'src/app/store/state/app.state';
import { sendShowcasesImpression } from 'src/app/store/actions/event.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-content-showcase',
  templateUrl: './home-content-showcase.component.html',
  styleUrls: ['./home-content-showcase.component.scss']
})
export class HomeContentShowcaseComponent implements OnInit, AfterViewInit {
  @ViewChild("myComponent")
  myComponent!: ElementRef;

  @Input()
  showcase: Showcase;

  @HostListener('window:resize', ['$event'])
  onResize = () => this.updateInnerWidth();

  public innerWidth: number;
  public theme = environment.theme;
  public type: string;
  public layout: string;

  public chaordicShowCase$: Observable<ShowcaseChaordic[]>;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private readonly viewService: ViewComponentService,
    private store: Store<AppState>,
  ) {
    this.chaordicShowCase$ = this.store.select(getShowcaseChaordic);
  }

  ngAfterViewInit(): void {
    if (this.showcase.recommendation) {
      this.viewService.build({ component: this.myComponent, threshold: 0.8, closeOnceRequest: false })
        .pipe(
          switchMap(() => this.chaordicShowCase$),
          take(1))
        .subscribe((resp) => {
          resp?.forEach(showcase => {
            if (this.showcase?.recommendation?.id === showcase.id) {
              this.store.dispatch(sendShowcasesImpression({ impressionUrl: showcase?.impressionUrl }));

              const list = this.showcase.recommendation?.items?.reduce((acc, current) => {
                const matchingItem = showcase?.items?.find(item => Number(current.id) === Number(item.id));
                acc.push(matchingItem ? { ...current, link: `${current.link}?trackingUrl=${matchingItem.trackingUrl}` } : current)
                return acc;
              }, []);

              this.showcase = { ...this.showcase, recommendation: { ...this.showcase.recommendation, items: list } };
            }
          });
        })
    }
  }

  ngOnInit() {
    this.updateInnerWidth();
    this.setTypeAndLayout();
  }

  setTypeAndLayout() {
    const layoutMap: { [key: string]: string } = {
      recommendation: this.showcase?.recommendation?.layout,
      banner: this.showcase?.banner?.type,
      story: this.showcase?.story?.type,
    }

    this.type = this.showcase?.type;
    this.layout = layoutMap[this.type];
  }


  private updateInnerWidth(): void {
    if (isPlatformBrowser(this.platformId)) this.innerWidth = window.innerWidth;
  }
}