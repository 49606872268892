import { Component, Input } from '@angular/core';
import { CardBannerDirective } from '../../banner-scroll/card-banner-scroll.directive';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state/app.state';
import * as tagManagerActions from '../../../store/actions/tagmanager.actions';
import { TagmanagerBuilder } from 'src/app/builders/tagmanager-builder';

@Component({
  selector: 'app-card-banner-noscroll',
  templateUrl: './card-banner-noscroll.component.html',
  styleUrls: ['./card-banner-noscroll.component.scss']
})
export class CardBannerNoscrollComponent extends CardBannerDirective {
  @Input() public first: boolean;
  @Input() public last: boolean;
  @Input() public oneImage: boolean;

  constructor(private store?: Store<AppState>) {
    super();
  }

  public tagManager(): void {
    window?.dataLayer?.push(
      TagmanagerBuilder.buildBannerSelectPromotion({ ...this.bannerItem, bannerPosition: 'footer' })
    );
    this.store.dispatch(tagManagerActions.pushBannerNameTagManager({ banner: this.bannerItem }));
  }

}
