import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ToolBar } from 'src/app/services/home/tool-bar-model';
import { ViewComponentService } from 'panvel-utils-lib';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-info-bar-mobile',
  templateUrl: './info-bar-mobile.component.html',
  styleUrls: ['./info-bar-mobile.component.scss']
})
export class InfoBarMobileComponent implements OnDestroy, AfterViewInit {
  @ViewChild("infoBarMobile") infoBarMobile!: ElementRef;
  @Input() public toolBar: ToolBar[] = [];

  private destroy$ = new Subject();
  public timer: any;
  public interval = 7000;
  public currentIndex = 0;

  constructor(private readonly viewService: ViewComponentService) { }

  ngOnDestroy(): void {
    clearInterval(this.timer);
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.viewService
      .build({ component: this.infoBarMobile, threshold: 0.8, closeOnceRequest: false, checkHidden: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe((isShowing) => {
        if (isShowing) {
          clearInterval(this.timer);
          this.timer = setInterval(() => this.nextImage(), this.interval);
          this.addSlide();
        } else clearInterval(this.timer);
      })
  }

  public updateInterval(): void {
    clearInterval(this.timer);
    this.removeSlide();
    this.timer = setInterval(() => this.nextImage(), this.interval);
  }

  public previousImage(): void {
    this.updateInterval();
    if (this.currentIndex === 0) {
      this.currentIndex = this.toolBar?.length - 1;
    } else {
      this.currentIndex--;
    }
    this.removeSlide();
    this.addSlide();
  }

  public nextImage(): void {
    this.updateInterval();
    if (this.toolBar?.length > 0) {
      this.currentIndex++;
      if (this.currentIndex === this.toolBar?.length) {
        this.currentIndex = 0;
      }
      this.removeSlide();
      this.addSlide();
    }
  }

  public changeSlideByIcon(nextSlide: number): void {
    this.removeSlide();
    this.currentIndex = nextSlide;
    this.addSlide();
  }

  public removeSlide(): boolean {
    return this.toolBar[this.currentIndex].status = false;
  }

  public addSlide(): boolean {
    return this.toolBar[this.currentIndex].status = true;
  }
}
