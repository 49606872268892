import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AddItemsRequest } from './model/request/add-item-request.model';
import { Cart } from 'panvel-utils-lib/lib/models/shopping-cart-response.model';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartApi {

  constructor(
    private http: HttpClient
  ) {
  }

  createCart(): Observable<Cart> {
    return this.http.put<Cart>(environment.baseHref + 'api/v1/cart/', null)
  }

  findCart(shoppingCartId: string): Observable<Cart> {
    const options = {
      'app-token': environment.appToken,
      shoppingCartId
    };
    return this.http.get<Cart>(environment.baseHref + 'api/v1/cart/',
      { headers: options, withCredentials: true });
  }

  addItemToCart(itemRequest: AddItemsRequest): Observable<Cart> {
    return this.http.post<Cart>(environment.baseHref + 'api/v1/cart/item', itemRequest);
  }
}
