import { Component } from '@angular/core';
import { BannerDirective } from '../../banner-scroll/banner-scroll.directive';

@Component({
  selector: 'app-banner-noscroll',
  templateUrl: './banner-noscroll.component.html',
  styleUrls: ['./banner-noscroll.component.scss']
})
export class BannerNoscrollComponent extends BannerDirective {
}
